<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> マスタ管理 - 優先度</h1>
    </header>


    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="selected_department_id"
                    :options="options_department"
                    :disabled="loading_count > 0"
                ></form-select>
            </div>
            <div class="col-6">
            <button class="btn btn-primary" :disabled="loading_count > 0" @click="search()">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <div v-else class="col-sm-36 col-md-26 col-lg-20">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-1"></th>
                        <th class="text-center col-8">優先度</th>
                        <th class="text-center col-2"></th>
                    </tr>
                </thead>
                <template v-if="probabilities.length">
                    <draggable
                        v-model="probabilities"
                        item-key="probability_id"
                        tag="tbody"
                        handle=".handle"
                        @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="align-middle text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle"><form-input required v-model="element.probability_name" /></td>
                                <td class="text-nowrap text-center">
                                    <button type="submit" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input required v-model="probability_to_create.probability_name" /></td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>


    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>

import draggable from 'vuedraggable';
import Department from '@/models/entities/department';
import Probability from '@/models/entities/probability';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'MasterProbability',
    components: {
        draggable,
        ConfirmDialog,
        InlineLoader,
        FormInput,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            loading_count: 0,
            selected_department_id: null,
            options_department: [],
            probabilities: [],
            probability_to_create: new Probability(),
            probability_to_remove: null,
        }
    },
    mounted() {
        this.fetchDepartments();
    },
    methods: {
        // 事業部一覧取得
        fetchDepartments() {
            // ローディング開始
            this.loading_count++;
            // option空に
            this.options_department.splice(0);

            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                //事業部未登録の場合
                if (this.options_department.length === 0) {
                    this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    this.$router.push({name: 'MasterDepartment'});
                }

                // 初期値事業部セット
                this.selected_department_id = this.options_department[0].value;

                // 優先度一覧取得
                this.search();
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // 検索
        search() {
            // 事業部セットされているか確認
            if (this.$helper.isBlank(this.selected_department_id)) {
                this.showErrorMessage('事業部を選択してください');
                return;
            }

            this.loading_count++;
            //表示データ初期化
            this.probabilities.splice(0);

            this.$http.get('/master/probability', {
                params: {
                    department: this.selected_department_id
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.probabilities.push(new Probability(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        //新規登録
        create() {
            if (this.$helper.isBlank(this.probability_to_create.probability_name)) {
                this.showErrorMessage("優先度を入力してください");
                return;
            }

            this.startScreenLoading();

            // 事業部セット
            this.probability_to_create.department_id = this.selected_department_id;

            this.$http.post('/master/probability', this.probability_to_create)
            .then(response => {
                // 登録されたデータをリストに追加
                this.probabilities.push(new Probability(response.data));
                // 追加行を初期化
                this.probability_to_create = new Probability();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        //保存
        update(probability) {
            if (this.$helper.isBlank(probability.probability_name)) {
                this.showErrorMessage('優先度を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/probability/${probability.probability_id}`, probability)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        //削除確認
        removeReady(probability) {
            this.probability_to_remove = probability;
            this.$refs.confirmRemove.show();
        },
        //削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/probability/${this.probability_to_remove.probability_id}`)
            .then(() => {
                // リストから削除
                let removed_index = this.probabilities.findIndex(
                    (row) => row.probability_id === this.probability_to_remove.probability_id
                );
                this.probabilities.splice(removed_index, 1);
                // 値をリセット
                this.probability_to_remove = null;

                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //ソート
        sort() {
            this.startScreenLoading();

            const probabilities = this.probabilities.map(probability_object => probability_object.probability_id);

            this.$http.post('/master/probability/sort', {
                probabilities: probabilities,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
